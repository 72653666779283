import { usePage } from '@inertiajs/vue3';
import { ref, watch, onMounted } from 'vue';
import ToastData = App.Data.ToastData;
import ToastType = App.Enums.ToastType;

export default function useToasts() {
  const page = usePage();
  const queue = ref<ToastData[]>([]);
  const duration = 5000;

  const dequeue = (): ToastData | null => {
    if (queue.value.length === 0) {
      return null;
    }

    return queue.value.shift() || null;
  };

  const enqueue = (label: string, type: ToastType = 'success') => {
    queue.value.push({
      label,
      type,
    });
  };

  onMounted(() => {
    if (page.props.flash.toasts.length) {
      page.props.flash.toasts.forEach((toast: ToastData) => enqueue(toast.label, toast.type));
    }
  });

  watch(() => page.props.flash.toasts, (newValue: ToastData[]) => {
    newValue.forEach((toast: ToastData) => enqueue(toast.label, toast.type));
  });

  return {
    enqueue,
    dequeue,
    duration,
    queue,
  };
}
